@font-face {
  font-family: 'Golos';
  font-style: normal;
  font-weight: 500;
  src: local('Golos'), local('Golos-Regular'),
    url('./fonts/Golos-Text_Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Golos';
  font-style: normal;
  font-weight: 400;
  src: local('Golos'), local('Golos-Regular'),
    url('./fonts/Golos-Text_Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'BasicallyASansSerif';
  font-style: normal;
  font-weight: 600;
  src: local('BasicallyASansSerif'), local('BasicallyASansSerif-SemiBold'),
    url('./fonts/BasicallyASansSerif-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: local('Inter'), local('Inter-Regular'), url('./fonts/Inter-400.ttf') format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: local('Inter'), local('Inter-Regular'), url('./fonts/Inter-500.ttf') format('truetype');
}
.css-19kzrtu {
  padding: 0;
}
*::-webkit-scrollbar {
  width: 9px;
  height: 9px;
  color: white;
}
*::-webkit-scrollbar-thumb {
  background-color: #ced0d9;
  border-radius: 8px;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.resize-animation-stopper * {
  animation: none !important;
  transition: none !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 599.95px) {
  .notistack-SnackbarContainer {
    width: auto !important;
  }
}

@media screen and (max-width: 432px) {
  .notistack-SnackbarContainer {
    width: 100% !important;
  }
}
