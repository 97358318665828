.wrapper {
  width: 600px;
  border: 1px solid #e7e8ec;
  border-radius: 6px;
  margin: 38px auto 0;
  padding: 32px;
  height: fit-content;
}

.continue-button {
  margin-top: 72px;
  margin-left: auto;
  display: block;
}

.select {
  width: 100% !important;
}
