.applications-table {
  height: calc(100vh - 118px);
  width: calc(100% - 533px);
  transition: 0.5s ease all;
}

.applications-table-empty {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.applications-table-admin {
  height: calc(100vh - 328px);
}

.full-width {
  transition: 0.5s ease all;
  width: 100%;
}

.header-button {
  padding: 0;
  min-width: 0;
  height: 100%;
}

.header-button:hover {
  background: none !important;
}

.wrapper {
  height: 100%;
}

.animate-width,
.animate-width div {
  transition: 0.5s ease all;
}

.table-cell {
  height: 80px;
  padding: 0px;
  flex: 1;
  border: none;
  overflow: hidden;
}

.table-cell .open-actions-icon:hover,
.active-actions-icon {
  background: #e7e8ec;
}

.table-cell .open-actions-icon:hover path,
.active-actions-icon path {
  fill: rgba(0, 0, 0, 0.8);
}

.info-icon path,
.info-icon circle {
  stroke: #0b1641;
}

.app-icon {
  background: #fff;
  border: 1px solid #e7e8ec;
  border-radius: 4px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-right: 32px;
}

.overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.header-column {
  overflow: hidden;
}

.header-cell {
  white-space: nowrap;
  height: 56px;
  padding: 0;
}

/* .tooltip {
  width: 190px;
  height: 137px;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  background: #fff;
  padding: 16px 24px;
}

.info-icon {
  margin-left: 8px;
} */

.table-row {
  border-bottom: 1px solid #e7e8ec;
  padding-left: 24px;
  padding-right: 24px !important;
  cursor: pointer;
}

.content-row {
  padding-right: 24px !important;
  width: 100% !important;
}

.content-row:hover {
  background: #fbfbfc;
}

.selected-row {
  background: #f5f6f8;
}

.selected-row:hover {
  background: #f1f1f4;
}

.flex-container {
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.open-actions-icon {
  width: 39px;
  height: 39px;
  border-radius: 0;
  padding: 0;
}

.actions-popover {
  border-radius: 1px;
}

.mb-8 {
  margin-bottom: 8px;
}

.divider {
  height: 1px;
  width: 100%;
  background: #e7e8ec;
}

.pointer {
  cursor: pointer;
}

.rotate-180 {
  transform: rotate(180deg);
}

.popover-paper {
  transform: translateX(40px) translateY(12px) !important;
}

.horizontal-direction-top {
  transform: translateX(40px) translateY(-12px) !important;
}

.table {
  overflow-x: hidden;
}


.paper {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 3px 7px rgba(0, 0, 0, 0.14);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 1px;
  padding: 8px 0;
  width: 290px;
  background: #fff;
}

.divider {
  height: 1px;
  width: 100%;
  background: #e7e8ec;
  margin-top: 8px;
}

.action-button {
  width: 100%;
  justify-content: flex-start;
  height: 48px;
  padding-left: 24px;
}

.action-button-icon {
  margin-right: 16px;
}

.app-link {
  text-decoration: none;
  width: fit-content;
}

.app-link:hover {
  text-decoration: underline;
}

.delete-modal {
  background: #fff;
  padding: 24px 24px 32px 32px;
  border-radius: 2px;
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.search-wrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 9px;
  background: #fff;
  width: calc(100% - 30px);
}

.search-wrapper .search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.input {
  box-sizing: border-box;
  height: 40px;
  border: 1px solid #e7e8ec;
  border-radius: 1px;
  font-family: Inter;
  padding-left: 37px;
  color: #3c4567;
}

.input:focus {
  padding-left: 36px;
  border-color: #606783;
  border-width: 2px;
}

.input-root::before,
.input-root::after {
  display: none;
}

.search-icon-button,
.delete-icon-button {
  position: absolute;
  padding: 0;
}

.search-icon-button {
  top: 15px;
  width: 40px;
}

.delete-icon-button {
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.search-icon-button:hover path,
.delete-icon-button:hover path {
  fill: #0b1641;
}

.info-icon path,
.info-icon circle {
  stroke: #0b1641;
}
