.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.row {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e7e8ec;
  align-items: center;
  position: relative;
  padding-left: 12px;
  padding-right: 24px !important;
}

.checkbox {
  margin-right: 5px;
  width: 48px;
  height: 48px;
}

.search-icon-button {
  flex-shrink: 0;
}

.search-icon-button:hover path,
.delete-icon-button:hover path {
  stroke: #0b1641;
}

.content-row {
  width: 100% !important;
  padding-right: 24px !important;
  cursor: pointer;
}

.content-row:hover {
  background: #fbfbfc;
}

.header-row {
  border-bottom: 1px solid #e7e8ec !important;
}

.header-button {
  padding: 0;
  min-width: 0;
  height: 100%;
}

.table-cell {
  border: none;
  padding: 0;
  flex: 1;
  overflow: hidden;
}

.actions-button-column {
  min-width: 40px;
}

.rotate-180 {
  transform: rotate(180deg);
}

.header-button:hover {
  background: none !important;
}

.divider {
  border-top: 1px solid #e7e8ec;
  border-bottom: 1px solid #e7e8ec;
  padding-left: 24px;
  padding-right: 32px;
  height: 56px;
  display: flex;
  align-items: center;
}

.users-top-block {
  height: 94px;
}

.create-user-button {
  margin-left: auto;
  margin-right: 32px;
}

.create-user-button svg path {
  stroke: #fff;
  stroke-width: 2;
}

.flex-container {
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.flex-container + .flex-container {
  margin-top: 24px;
}

.view {
  padding-right: 0;
  height: 56px;
}

.view:hover {
  background: none;
}

.view-icon {
  margin-left: 4px;
  margin-right: 0;
}

.view-popover-paper {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 3px 7px rgba(0, 0, 0, 0.14);
  display: flex;
  border-radius: 1px;
  width: 372px;
  height: 186px;
}

.header-button {
  padding: 0;
  min-width: 0;
  height: 100%;
}

.header-button:hover {
  background: none !important;
}

.search-input {
  height: 40px;
  color: #3c4567;
  font-family: Inter;
}

.search-input input {
  padding-left: 16px;
}

.search-input::before {
  display: none;
}

.search-input::after {
  display: none;
}

.open-actions-icon {
  width: 39px;
  height: 39px;
  border-radius: 0;
  padding: 0;
}

.table-cell .open-actions-icon:hover,
.active-actions-icon {
  background: #e7e8ec;
}

.table-cell .open-actions-icon:hover path,
.active-actions-icon path {
  fill: rgba(0, 0, 0, 0.8);
}

.popover-paper {
  transform: translateX(40px) translateY(12px) !important;
}

.horizontal-direction-top {
  transform: translateX(40px) translateY(-12px) !important;
}

.paper {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 3px 7px rgba(0, 0, 0, 0.14);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 1px;
  padding: 8px 0;
  width: 290px;
  background: #fff;
}

.action-button {
  width: 100%;
  justify-content: flex-start;
  height: 48px;
  padding-left: 24px;
}

.action-button-icon {
  margin-right: 16px;
}

.avatar {
  background-color: #f89f33;
  width: 40px;
  height: 40px;
  margin-right: 24px;
}

.avatar svg {
  height: 32px;
  width: 32px;
}

.avatar ellipse,
.avatar path {
  stroke: #fff;
}

.user-link {
  cursor: pointer;
  margin-bottom: 6px;
}

.custom-popover-button:nth-child(3) {
  border-top: 1px solid #e7e8ec;
}

.custom-popover-button:nth-child(4) {
  border-top: 1px solid #e7e8ec;
}

.app-icon-wrapper {
  flex-shrink: 0;
  border: 1px solid #e7e8ec;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
  margin-right: 25px;
}

.app-icon-default {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #f89f33;
  font-size: 15px;
  text-transform: uppercase;
}

.selected-row {
  background: #f5f6f8;
}

.selected-row:hover {
  background: #f1f1f4;
}

.selected-checked-row {
  background: #e7e8ec;
}

.selected-checked-row:hover {
  background: #e7e8ec;
}

.table-wrapper {
  height: calc(100vh - 214px);
}

.users-wrapper {
  padding-left: 32px;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.animate-width,
.animate-width div {
  transition: 0.5s ease all;
}

.column {
  transition: 0.5s ease flex;
  overflow: hidden;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.header-row > div {
  transition: 0.5s ease flex;
  overflow: hidden;
}

.modal-wrapper {
  background: #fff;
  padding: 24px 24px 32px 32px;
  border-radius: 1px;
  max-width: 500px;
  max-height: 100vh;
  overflow: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.apps-to-delete {
  min-height: 67px;
  padding-right: 5px;
  max-height: calc(100vh - 570px);
  overflow-y: auto;
}

.app-to-delete {
  display: flex;
  align-items: center;
  border: 1px solid #e7e8ec;
  border-radius: 6px;
  padding: 12px 16px;
}

.app-to-delete + .app-to-delete {
  margin-top: 16px;
}

.app-icon {
  background: #fff;
  border: 1px solid #e7e8ec;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-right: 32px;
}

.app-link {
  max-width: calc(100% - 90px);
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  color: #4c6ad4;
}

.app-link:hover {
  text-decoration: underline;
}

.radio-delete-users {
  margin-bottom: 10px;
}

.radio-delete-apps {
  margin-bottom: 24px;
}

@media screen and (max-width: 1023px) {
  .wrapper {
    height: calc(100vh - 65px);
  }
}

.select {
  margin-left: auto;
  margin-right: 16px;
  width: 290px !important;
  border-radius: 0;
  padding-right: 8px;
}

.select-item {
  padding-left: 55px;
  display: block;
  position: relative;
}

.input-tooltip {
  background: #0b1641;
}

.input-tooltip-arrow {
  color: #0b1641;
}
