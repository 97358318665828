.radio-group {
  padding-left: 24px;
  display: flex;
}

.view-radio {
  display: flex;
  flex-direction: column;
}

.view-radio + .view-radio {
  margin-left: 40px;
}

.radio-button {
  padding: 0;
  margin: 24px 0 12px;
}

.margin {
  margin: 0;
}