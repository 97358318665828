.padding-wrapper {
  padding: 32px;
}

.subtitle {
  margin-bottom: 24px;
}

.input-title {
  margin-bottom: 8px;
}

.textfield {
  margin-bottom: 24px;
}

.select {
  width: 100% !important;
}

.input-error {
  color: #fc545c;
  margin-bottom: 8px;
}

.submit-buttons {
  margin-left: auto;
  width: fit-content;
  margin-top: 32px;
}

.create-button {
  margin-left: 16px;
}

.providers-with-avatars-modal {
  max-height: 80%;
  background-color: #fff;
  max-width: 80%;
  padding: 32px;
}

.providers-wrapper {
  overflow: auto;
  height: 320px;
}

.account-name-wrapper {
  flex: 1 1 auto;
  overflow: hidden;
  margin-right: 24px;
}

.account-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.search-icon {
  position: absolute;
  left: 12px;
}

.search {
  margin-bottom: 31px;
}

.input {
  padding-left: 48px !important;
}

.account-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-width: 40px;
  background: #f1f1f4;
  border: 1px solid #e7e8ec;
  border-radius: 4px;
}

.account-icon-wrapper {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 24px;
}

.input-description {
  margin-bottom: 16px;
}

.provider {
  display: flex;
  background: #fff;
  align-items: center;
  height: 64px;
  border: 1px solid #e7e8ec;
  border-radius: 4px;
  margin-right: 8px;
  padding: 0 32px 0 18px;
  cursor: pointer;
}

.provider:hover {
  background: #f5f6f8;
}

.provider + .provider {
  margin-top: 16px;
}

.checked-provider {
  background: #f5f6f8;
}
