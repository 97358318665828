.wrapper {
  overflow-y: auto;
  width: 100%;
  height: calc(100vh - 118px);
}

.content {
  padding: 32px 0 0 32px;
  width: 100%;
  max-width: 1032px;
}

.bar {
  margin-bottom: 32px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.search {
  margin-right: 32px;
  width: 523px;
  min-width: 120px;
  max-width: 523px;
  height: 40px;
  max-height: 40px;
}

.search-icon {
  position: absolute;
  left: 12px;
}

.input {
  padding-left: 48px !important;
  min-height: 38px !important;
}

.date-range-picker {
  margin-right: 32px;
  display: flex;
  align-items: center;
  height: 40px;
}

.date-picker {
  padding-left: 16px;
  width: 150px;
  height: 40px;
  border: 1px solid #e7e8ec;
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.refresh-button {
  margin-right: 24px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  border-radius: 8px;
  border: 1px solid #e7e8ec;
}

.refresh-button:hover {
  background: #fbfbfc;
}

.refresh-icon path {
  fill: #0b1641;
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: loading 2s linear infinite;
}

.events {
  position: relative;
  width: 100%;
  height: calc(100vh - 283px);
}

.event {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.event-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  width: 100%;
  height: 80px;
  border-radius: 8px;
  border: 1px solid #e7e8ec;
}

.event-inner:hover {
  background: #fbfbfc;
}

.icons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  overflow: hidden;
  width: 56px;
  height: 56px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 50%;
  border: 1px solid #e7e8ec;
}

.icon-default {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  font-size: 22px;
  text-transform: uppercase;
  background: #ecedf0;
}

.message-wrapper {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.message {
  margin-bottom: 4px;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.empty {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.empty-inner {
  position: relative;
}

.empty-icon {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%);
  width: 140px;
  height: 140px;
}
