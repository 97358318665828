.wrapper {
  padding: 32px 32px 0;
  padding-bottom: 20px;
  height: fit-content;
}

.button-back {
  padding: 0;
}

.button-back:hover {
  background: none;
}

.arrow-icon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  transform: rotate(-90deg);
}

.change-email {
  width: 600px;
  border: 1px solid #e7e8ec;
  border-radius: 6px;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  padding: 32px;
}

.textfield {
  margin-bottom: 8px;
}

.button-back {
  padding: 0;
}

.wrapper .button-back:hover {
  background: none;
}
