.wrapper {
  background: #fff;
  padding: 27px 32px 0 32px;
  width: 480px;
}

.header {
  margin-bottom: 24px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.close-button path {
  stroke: #0b1641;
}

.close-button:hover {
  background: #f5f6f8 !important;
  border-radius: 0;
}

.license {
  margin-left: 32px;
}

.licenseWrapper {
  display: flex;
  background: #fff;
  align-items: center;
  height: 64px;
  border: 1px solid #e7e8ec;
  border-radius: 4px;
}

.licenseWrapper + .licenseWrapper {
  margin-top: 16px;
}

.license-button {
  padding: 0;
  margin-left: auto;
  margin-right: 32px;
}

.input-tooltip {
  background: #0b1641;
}

.input-tooltip-arrow {
  color: #0b1641;
}

.activateButton {
  margin-left: auto;
  margin-right: 8px;
}

.input {
  width: auto;
  flex-grow: 1;
}

.textField {
  margin-bottom: 32px;
}
