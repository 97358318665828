.panel {
  overflow: hidden;
  height: 100%;
  width: 100%;
  background: #fff;
  padding: 32px 0 0 32px;
}

.button-back {
  padding: 0;
}

.panel .button-back:hover {
  background: none;
}

.arrow-icon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  transform: rotate(-90deg);
}

.tabs .tab-indicator {
  height: 1px;
}

.tabs {
  padding: 0 32px 0 24px;
  border-bottom: 1px solid #e7e8ec;
  border-top: 1px solid #e7e8ec;
}

.tabs-wrapper {
  border-bottom: 1px solid #e7e8ec;
}

.tabs .tab {
  padding: 0;
  min-width: 0;
  width: fit-content;
  height: 56px;
  text-transform: none;
}

.tab + .tab {
  margin-left: 32px;
}

.tabs .view {
  margin-left: auto;
  padding-right: 0;
}

.tabs .view:hover {
  background: none;
}

.view-icon {
  margin-left: 4px;
}

.panel-top {
  display: flex;
  padding: 32px 32px 32px 0;
  align-items: center;
}

.app-icon-wrapper {
  flex-shrink: 0;
  border: 1px solid #e7e8ec;
  border-radius: 8px;
  width: 88px;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
}

.app-icon {
  width: 40px;
  height: 40px;
}

.name-wrapper {
  margin-left: 32px;
  width: calc(100% - 210px);
}

.edit-link {
  display: block;
  margin-left: 24px;
}

.go-over-wrapper {
  padding: 8px;
  margin-left: auto;
  cursor: pointer;
}

.action-button-icon {
  margin-right: 16px;
}

.paper {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 3px 7px rgba(0, 0, 0, 0.14);
  display: flex;
  transition: none !important;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 1px;
  padding: 8px 0;
  width: 290px;
  background: #fff;
}

.divider {
  height: 1px;
  width: 100%;
  background: #e7e8ec;
  margin-top: 8px;
}

.info-icon path,
.info-icon circle {
  stroke: #0b1641;
}

.overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.view-popover-paper {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 3px 7px rgba(0, 0, 0, 0.14);
  display: flex;
  border-radius: 1px;
  width: 372px;
  height: 186px;
}

.mobile-buttons {
  display: flex;
  padding-bottom: 24px;
  padding-top: 16px;
}

.delete-modal {
  background: #fff;
  padding: 24px 24px 32px 32px;
  border-radius: 2px;
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.user-buttons {
  margin-top: 16px;
  display: flex;
  align-items: flex-end;
}

@media screen and (max-width: 1023px) {
  .panel {
    padding: 24px 16px 0 16px;
  }

  .panel-top {
    padding-bottom: 0;
    padding-top: 27px;
  }

  .popover-button {
    flex-grow: 1;
  }

  .user-buttons {
    margin-top: 0;
  }
}
