.menu {
  width: 157px;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 3px 7px rgba(0, 0, 0, 0.14);
  border-radius: 0;
}

.menu ul {
  padding: 0;
  border-radius: 0;
}

.select {
  width: 200px;
  height: 40px;
  border-radius: 0;
}

.select + .select {
  margin-left: 32px;
}

.select svg {
  top: calc(50% - 12px);
  right: 12px;
}

.select fieldset {
  border-color: #e7e8ec;
}

.select-item {
  padding-left: 55px;
  display: block;
  position: relative;
}

.check-icon {
  display: none;
  position: absolute;
  left: 24px;
  top: 50%;
  transform: translateY(-50%);
}

.select-item-selected {
  background-color: #f5f6f8 !important;
}

.select-item-selected .check-icon {
  display: block;
}

.outlined {
  padding: 10px 14px;
}
