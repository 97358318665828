.info-item {
  display: flex;
  align-items: center;
  min-height: 64px;
  max-height: 128px;
}

.info-item + .info-item {
  border-top: 1px solid #e7e8ec;
}

.provider-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-width: 40px;
  background: #f1f1f4;
  border: 1px solid #e7e8ec;
  border-radius: 4px;
}

.provider-icon-wrapper {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  background-size: cover;
  background-repeat: no-repeat;
}

.provider-info {
  margin-left: 24px;
  overflow: hidden;
}

.ellipsis {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.actions-icon {
  width: 39px;
  height: 39px;
  border-radius: 0;
  padding: 0;
}

.actions-icon:hover,
.active-actions-icon {
  background: #f5f6f8 !important;
}

.actions-icon:hover path,
.active-actions-icon path {
  fill: rgba(0, 0, 0, 0.8);
}

.paper {
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transform: translateX(40px) translateY(12px) !important;
  border-radius: 1px;
  background: #fff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 3px 7px rgba(0, 0, 0, 0.14);
}

.popover-button {
  padding: 8px 24px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  text-align: start;
}

@media screen and (max-width: 767px) {
  .provider-info {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
  }
}