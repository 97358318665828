.arrow-icon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  transform: rotate(-90deg);
}

.title {
  margin-top: 32px;
  margin-left: 32px;
}

.button-back {
  padding: 0;
}

.wrapper {
  padding: 32px 32px 0;
  padding-bottom: 20px;
  height: fit-content;
}

.wrapper .button-back:hover {
  background: none;
}

.input-root::before,
.input-root::after {
  display: none;
}

.field-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
}

.input {
  box-sizing: border-box;
  height: 40px;
  border: 1px solid #e7e8ec;
  border-radius: 1px;
  font-family: Inter;
  color: #3c4567;
  padding-left: 16px;
}

.input-subtitle {
  margin-bottom: 32px;
}

.add-text-field {
  flex-grow: 1;
  margin-right: 30px;
}

.add-button,
.delete-button {
  white-space: nowrap;
  flex-shrink: 0;
}

.add-button {
  margin-top: 8px;
}

.input-root textarea {
  box-sizing: border-box;
  height: 56px !important;
  border: 1px solid #e7e8ec;
  border-radius: 1px;
  outline: none;
  resize: none;
  font-family: Inter;
  color: #3c4567;
  padding-left: 16px;
}

.input:hover,
.input-root textarea:hover {
  border-color: #858ba0;
}

.input:focus,
.input-root textarea:focus {
  border-color: #606783;
  padding-left: 15px;
  border-width: 2px;
}

.helper-text {
  margin-top: 8px;
}

.asterisk::after {
  content: '*';
  color: red;
}

.add-button-bottom {
  margin-top: 0;
  margin-bottom: 20px;
}

.create-client-form {
  margin-top: 32px;
  border: 1px solid #e7e8ec;
  border-radius: 6px;
  width: 600px;
}

/* #427
.divider {
  width: 100%;
  height: 1px;
  background: #e7e8ec;
} */

/* #427
.switch-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 56px;
} */

.token-input {
  position: relative;
}

.token-textfield {
  margin-bottom: 32px;
}

.token-input::after {
  content: 'секунд';
  display: block;
  left: auto;
  border: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) !important;
  right: 32px;
  margin: 0;
  color: #b6bac6;
  font-family: 'Inter';
  font-size: 14px;
  line-height: 21px;
}

.menu {
  width: 157px;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 3px 7px rgba(0, 0, 0, 0.14);
  border-radius: 0;
}

.menu ul {
  padding: 0;
  border-radius: 0;
}

.save-modal {
  background: #fff;
  padding: 24px 24px 32px 32px;
  border-radius: 2px;
  border: none;
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 1023px) {
  .wrapper {
    width: 100%;
    padding: 33px 16px 0;
  }

  .padding-wrapper {
    padding: 25px 0;
  }

  .create-client-form {
    margin-top: 0;
    height: calc(100vh - 124px);
    border: none;
    width: 100%;
  }

  .title {
    margin-bottom: 24px;
  }
}
